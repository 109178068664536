exports.professions = [
  {id: "Accountancy", name: "Accountancy"},
  {id: "Acupuncture", name: "Acupuncture"},
  {id: "Applied Behavior Analysts", name: "Applied Behavior Analysts"},
  {id: "Approved Hosp. Training Prog.", name: "Approved Hosp. Training Prog."},
  {id: "Architecture", name: "Architecture"},
  {id: "Art Therapist Advisory Comm", name: "Art Therapist Advisory Comm"},
  {id: "Athletic Trainers", name: "Athletic Trainers"},
  {id: "Audiology", name: "Audiology"},
  {id: "Cemetery", name: "Cemetery"},
  {id: "Cert Psychoanalysts Adv Comm", name: "Cert Psychoanalysts Adv Comm"},
  {id: "Chiropractic Examiners", name: "Chiropractic Examiners"},
  {id: "Cosmetology and Hairstyling", name: "Cosmetology and Hairstyling"},
  {id: "Court Reporting", name: "Court Reporting"},
  {id: "Creative Arts", name: "Creative Arts"},
  {id: "Dentistry", name: "Dentistry"},
  {id: "Diet and Nutrition", name: "Diet and Nutrition"},
  {id: "EEMW", name: "EEMW"},
  {id: "Electrical Contractors", name: "Electrical Contractors"},
  {id: "Electrologists", name: "Electrologists"},
  {id: "Engineers &amp; Land Surveyors", name: "Engineers &amp; Land Surveyors"},
  {id: "Genetic Counseling", name: "Genetic Counseling"},
  {id: "Hearing Aid Dispensers", name: "Hearing Aid Dispensers"},
  {id: "Home Improvement Contractors", name: "Home Improvement Contractors"},
  {id: "Home Inspection Advisory Comm", name: "Home Inspection Advisory Comm"},
  {id: "HVACR", name: "HVACR"},
  {id: "Interior Designers", name: "Interior Designers"},
  {id: "Int'l Labor and Matchmaking", name: "Int'l Labor and Matchmaking"},
  {id: "Kosher / Hallal", name: "Kosher / Hallal"},
  {id: "Landscape Architecture", name: "Landscape Architecture"},
  {id: "Legalized Games", name: "Legalized Games"},
  {id: "Marriage and Family Therapy", name: "Marriage and Family Therapy"},
  {id: "Massage and Bodywork Therapy", name: "Massage and Bodywork Therapy"},
  {id: "Master Hearth Adv Committee", name: "Master Hearth Adv Committee"},
  {id: "Master Plumbers", name: "Master Plumbers"},
  {id: "Medical Examiners", name: "Medical Examiners"},
  {id: "Mortuary Science", name: "Mortuary Science"},
  {id: "Nursing", name: "Nursing"},
  {id: "Occupational Therapy", name: "Occupational Therapy"},
  {id: "Ophthalmic Dispensers", name: "Ophthalmic Dispensers"},
  {id: "Optometry", name: "Optometry"},
  {id: "Orthotics &amp; Prosthetics", name: "Orthotics &amp; Prosthetics"},
  {id: "Perfusionist", name: "Perfusionist"},
  {id: "Pharmacy", name: "Pharmacy"},
  {id: "Physical Therapy", name: "Physical Therapy"},
  {id: "Physician Assistants", name: "Physician Assistants"},
  {id: "Polysomnography", name: "Polysomnography"},
  {id: "Professional Planners", name: "Professional Planners"},
  {id: "Psychology", name: "Psychology"},
  {id: "Public Movers and Warehousemen", name: "Public Movers and Warehousemen"},
  {id: "Real Estate Appraisers", name: "Real Estate Appraisers"},
  {id: "Respiratory Care", name: "Respiratory Care"},
  {id: "Social Work Examiners", name: "Social Work Examiners"},
  {id: "Vehicle Protection", name: "Vehicle Protection"},
  {id: "Veterinary Medical Examiners", name: "Veterinary Medical Examiners"}
];

exports.licenseTypes = [
  {id: "1 Year Temporary Permit", name: "1 Year Temporary Permit"},
  {id: "3 Year Temporary Permit", name: "3 Year Temporary Permit"},
  {id: "Acupuncturist", name: "Acupuncturist"},
  {id: "ADC Intern", name: "ADC Intern"},
  {id: "Adv. Practice Nurse - Non Resident", name: "Adv. Practice Nurse - Non Resident"},
  {id: "Adv. Practice Nurse - Resident", name: "Adv. Practice Nurse - Resident"},
  {id: "AMC Controlling Person", name: "AMC Controlling Person"},
  {id: "Apprentice Ophthalmic Disp Branch", name: "Apprentice Ophthalmic Disp Branch"},
  {id: "Apprentice Ophthalmic Dispenser", name: "Apprentice Ophthalmic Dispenser"},
  {id: "Apprentice Ophthalmic Tech Branch", name: "Apprentice Ophthalmic Tech Branch"},
  {id: "Apprentice Ophthalmic Technician", name: "Apprentice Ophthalmic Technician"},
  {id: "Apprentice Plumber", name: "Apprentice Plumber"},
  {id: "Approved Instructor", name: "Approved Instructor"},
  {id: "Architect Exam Candidate", name: "Architect Exam Candidate"},
  {id: "Assoc Counselor Graduate License", name: "Assoc Counselor Graduate License"},
  {id: "Assoc Marriage &amp; Family Therapist", name: "Assoc Marriage &amp; Family Therapist"},
  {id: "Associate Art Therapist", name: "Associate Art Therapist"},
  {id: "Associate Art Therapist", name: "Associate Art Therapist"},
  {id: "Associate Counselor", name: "Associate Counselor"},
  {id: "Associate Dance Movement Therapist", name: "Associate Dance Movement Therapist"},
  {id: "Associate Drama Therapist", name: "Associate Drama Therapist"},
  {id: "Associate Home Inspector", name: "Associate Home Inspector"},
  {id: "Athletic Trainer", name: "Athletic Trainer"},
  {id: "Athletic Trainer Temporary", name: "Athletic Trainer Temporary"},
  {id: "Audiologist", name: "Audiologist"},
  {id: "Audiologist Non-Renewable Temp", name: "Audiologist Non-Renewable Temp"},
  {id: "Audiologist Temporary License", name: "Audiologist Temporary License"},
  {id: "Audiologist-Speech Lang Path", name: "Audiologist-Speech Lang Path"},
  {id: "Barber", name: "Barber"},
  {id: "Beautician", name: "Beautician"},
  {id: "Bioanalytical Lab Dir - Spec", name: "Bioanalytical Lab Dir - Spec"},
  {id: "Bioanalytical Laboratory Dir", name: "Bioanalytical Laboratory Dir"},
  {id: "Branch OM Certification", name: "Branch OM Certification"},
  {id: "Branch TPA Certification", name: "Branch TPA Certification"},
  {id: "Burglar Alarm License", name: "Burglar Alarm License"},
  {id: "CADC Intern Temp License", name: "CADC Intern Temp License"},
  {id: "CDS Advanced Practice Nurse", name: "CDS Advanced Practice Nurse"},
  {id: "CDS APN Branch", name: "CDS APN Branch"},
  {id: "CDS Certified Nurse Midwife", name: "CDS Certified Nurse Midwife"},
  {id: "CDS CNM Branch", name: "CDS CNM Branch"},
  {id: "CDS Dentist", name: "CDS Dentist"},
  {id: "CDS Dentist Branch", name: "CDS Dentist Branch"},
  {id: "CDS Optometrist", name: "CDS Optometrist"},
  {id: "CDS Optometrist Branch", name: "CDS Optometrist Branch"},
  {id: "CDS PA Branch", name: "CDS PA Branch"},
  {id: "CDS Physician", name: "CDS Physician"},
  {id: "CDS Physician Assistant", name: "CDS Physician Assistant"},
  {id: "CDS Physician Branch", name: "CDS Physician Branch"},
  {id: "CDS Podiatrist", name: "CDS Podiatrist"},
  {id: "CDS Podiatrist Branch", name: "CDS Podiatrist Branch"},
  {id: "CDS Veterinarian", name: "CDS Veterinarian"},
  {id: "CDS Veterinarian Branch", name: "CDS Veterinarian Branch"},
  {id: "CE Provider", name: "CE Provider"},
  {id: "CE Provider - Person", name: "CE Provider - Person"},
  {id: "CE Sponsor (Person)", name: "CE Sponsor (Person)"},
  {id: "CE Sponsors-Individual", name: "CE Sponsors-Individual"},
  {id: "Cert Residential Appraiser", name: "Cert Residential Appraiser"},
  {id: "Cert. Realtime Court Reporter", name: "Cert. Realtime Court Reporter"},
  {id: "Certified Alcohol &amp; Drug Coun.", name: "Certified Alcohol &amp; Drug Coun."},
  {id: "Certified Court Reporter", name: "Certified Court Reporter"},
  {id: "Certified General Appraiser", name: "Certified General Appraiser"},
  {id: "Certified Midwife", name: "Certified Midwife"},
  {id: "Certified Nurse Midwife", name: "Certified Nurse Midwife"},
  {id: "Certified Professional Midwife", name: "Certified Professional Midwife"},
  {id: "Certified Psychoanalyst", name: "Certified Psychoanalyst"},
  {id: "Certified Public Accountant", name: "Certified Public Accountant"},
  {id: "Certified Social Worker", name: "Certified Social Worker"},
  {id: "Chiropractic Assistant", name: "Chiropractic Assistant"},
  {id: "Chiropractor", name: "Chiropractor"},
  {id: "Class A Journeyman Electrician", name: "Class A Journeyman Electrician"},
  {id: "Clinical Dance Movement Therapist", name: "Clinical Dance Movement Therapist"},
  {id: "Clinical Drama Therapist", name: "Clinical Drama Therapist"},
  {id: "CNM w/Prescriptive Authority", name: "CNM w/Prescriptive Authority"},
  {id: "Collaborative Practice", name: "Collaborative Practice"},
  {id: "Compact Licensee - Other State", name: "Compact Licensee - Other State"},
  {id: "Compensated Worker", name: "Compensated Worker"},
  {id: "Cosmetologist-Hairstylist", name: "Cosmetologist-Hairstylist"},
  {id: "CPC Class Registration", name: "CPC Class Registration"},
  {id: "Dental Assistant Internship", name: "Dental Assistant Internship"},
  {id: "Dental Assistant Ortho Intern", name: "Dental Assistant Ortho Intern"},
  {id: "Dental Hygienist", name: "Dental Hygienist"},
  {id: "Dental Hygienist Branch", name: "Dental Hygienist Branch"},
  {id: "Dentist", name: "Dentist"},
  {id: "Dentist Branch", name: "Dentist Branch"},
  {id: "Dispenser Training Permit", name: "Dispenser Training Permit"},
  {id: "DO NOT URen Temp Physical Therapist Asst", name: "DO NOT URen Temp Physical Therapist Asst"},
  {id: "DO NOT USE Ren Temp Physical Therapist", name: "DO NOT USE Ren Temp Physical Therapist"},
  {id: "DO NOT USE Temp Physical Therapist Asst", name: "DO NOT USE Temp Physical Therapist Asst"},
  {id: "DO NOT USE Temporary Physical Therapist", name: "DO NOT USE Temporary Physical Therapist"},
  {id: "Doctor of Osteopathy", name: "Doctor of Osteopathy"},
  {id: "Dog Handler/Trainer", name: "Dog Handler/Trainer"},
  {id: "Electrical Contractor", name: "Electrical Contractor"},
  {id: "Electrodiagnostic Permit", name: "Electrodiagnostic Permit"},
  {id: "Electrologist", name: "Electrologist"},
  {id: "Elevator Mechanic", name: "Elevator Mechanic"},
  {id: "Endodontics Specialty Br Prmt", name: "Endodontics Specialty Br Prmt"},
  {id: "Endodontics Specialty Permit", name: "Endodontics Specialty Permit"},
  {id: "Engineer-In-Training", name: "Engineer-In-Training"},
  {id: "Enteral Sedation Branch Permit", name: "Enteral Sedation Branch Permit"},
  {id: "Enteral Sedation Permit", name: "Enteral Sedation Permit"},
  {id: "Exam Provider", name: "Exam Provider"},
  {id: "FBL CE Sponsor (Person)", name: "FBL CE Sponsor (Person)"},
  {id: "Fire Alarm License", name: "Fire Alarm License"},
  {id: "For. Nurse CSA  - Non Resident", name: "For. Nurse CSA  - Non Resident"},
  {id: "For. Nurse CSA - Resident", name: "For. Nurse CSA - Resident"},
  {id: "General Anesthesia Branch Permit", name: "General Anesthesia Branch Permit"},
  {id: "General Anesthesia Permit", name: "General Anesthesia Permit"},
  {id: "Genetic Counselor", name: "Genetic Counselor"},
  {id: "Genetic Counselor Work Permit", name: "Genetic Counselor Work Permit"},
  {id: "Hair Braider", name: "Hair Braider"},
  {id: "Hearing Aid Dispenser", name: "Hearing Aid Dispenser"},
  {id: "Herbology", name: "Herbology"},
  {id: "HHA Program Instructors", name: "HHA Program Instructors"},
  {id: "Home Inspector", name: "Home Inspector"},
  {id: "Homemaker - HHA", name: "Homemaker - HHA"},
  {id: "Hospital", name: "Hospital"},
  {id: "HVACR CE Sponsor", name: "HVACR CE Sponsor"},
  {id: "Immunization Approval", name: "Immunization Approval"},
  {id: "Interim Business Qualifier", name: "Interim Business Qualifier"},
  {id: "Interior Designer", name: "Interior Designer"},
  {id: "INTL Physician Temporary License", name: "INTL Physician Temporary License"},
  {id: "Issuer Agent", name: "Issuer Agent"},
  {id: "Journeyman Plumber", name: "Journeyman Plumber"},
  {id: "Land Surveyor-In-Training", name: "Land Surveyor-In-Training"},
  {id: "Lic. Clin. Alcohol &amp; Drug Coun.", name: "Lic. Clin. Alcohol &amp; Drug Coun."},
  {id: "Lic. Clinical Social Worker", name: "Lic. Clinical Social Worker"},
  {id: "Lic. Prac. Nurse - Single State", name: "Lic. Prac. Nurse - Single State"},
  {id: "Lic. Prac.Nurse - Multi State", name: "Lic. Prac.Nurse - Multi State"},
  {id: "Licensed Landscape Architect", name: "Licensed Landscape Architect"},
  {id: "Licensed Residential Appraiser", name: "Licensed Residential Appraiser"},
  {id: "Licensed Social Worker", name: "Licensed Social Worker"},
  {id: "Limited Professional Certificate", name: "Limited Professional Certificate"},
  {id: "Limited Reg'd Ortho Assistant", name: "Limited Reg'd Ortho Assistant"},
  {id: "Limited Registered Dental Asst", name: "Limited Registered Dental Asst"},
  {id: "Limited Teaching Certificate", name: "Limited Teaching Certificate"},
  {id: "Local Anesthesia Branch Permit", name: "Local Anesthesia Branch Permit"},
  {id: "Local Anesthesia Permit", name: "Local Anesthesia Permit"},
  {id: "Locksmith License", name: "Locksmith License"},
  {id: "LPN Graduate License", name: "LPN Graduate License"},
  {id: "LSW Graduate License", name: "LSW Graduate License"},
  {id: "Manicurist", name: "Manicurist"},
  {id: "Marriage and Family Therapist", name: "Marriage and Family Therapist"},
  {id: "Massage and Bodywork Therapist", name: "Massage and Bodywork Therapist"},
  {id: "Massage Business Owner/Manager", name: "Massage Business Owner/Manager"},
  {id: "Massage, Bodywork &amp; Somatic Therapist", name: "Massage, Bodywork &amp; Somatic Therapist"},
  {id: "Master Hearth Specialist", name: "Master Hearth Specialist"},
  {id: "Master HVACR Contractor", name: "Master HVACR Contractor"},
  {id: "Master Plumber", name: "Master Plumber"},
  {id: "MD-Acupuncture Certification", name: "MD-Acupuncture Certification"},
  {id: "Medical Doctor", name: "Medical Doctor"},
  {id: "Medical Gas Piping Brazer", name: "Medical Gas Piping Brazer"},
  {id: "Medical Gas Piping Installer", name: "Medical Gas Piping Installer"},
  {id: "Medical Gas Piping Instructor", name: "Medical Gas Piping Instructor"},
  {id: "Military Temp Courtesy Lic LPN", name: "Military Temp Courtesy Lic LPN"},
  {id: "Military Temp Courtesy Lic RPN", name: "Military Temp Courtesy Lic RPN"},
  {id: "Mortuary Intern", name: "Mortuary Intern"},
  {id: "Mortuary Practitioner", name: "Mortuary Practitioner"},
  {id: "Non FINRA  Agent", name: "Non FINRA  Agent"},
  {id: "non-license", name: "non-license"},
  {id: "Occupational Therapist", name: "Occupational Therapist"},
  {id: "Occupational Therapist -Temp", name: "Occupational Therapist -Temp"},
  {id: "Occupational Therapist -Temp Renewal", name: "Occupational Therapist -Temp Renewal"},
  {id: "Occupational Therapy Asst", name: "Occupational Therapy Asst"},
  {id: "Occupational Therapy Asst-TEMP", name: "Occupational Therapy Asst-TEMP"},
  {id: "Occupational Therapy Asst-TEMP RENEWAL", name: "Occupational Therapy Asst-TEMP RENEWAL"},
  {id: "OM Certification", name: "OM Certification"},
  {id: "One-Year Permit", name: "One-Year Permit"},
  {id: "Ophthalmic Dispenser", name: "Ophthalmic Dispenser"},
  {id: "Ophthalmic Dispenser Branch Office", name: "Ophthalmic Dispenser Branch Office"},
  {id: "Ophthalmic Technician", name: "Ophthalmic Technician"},
  {id: "Ophthalmic Technician Branch Office", name: "Ophthalmic Technician Branch Office"},
  {id: "Optometrist", name: "Optometrist"},
  {id: "Optometrist - Branch", name: "Optometrist - Branch"},
  {id: "Optometrist - Inactive", name: "Optometrist - Inactive"},
  {id: "Oral &amp; Max Rad Spec Br Prmt", name: "Oral &amp; Max Rad Spec Br Prmt"},
  {id: "Oral &amp; Max Rad Spec Prmt", name: "Oral &amp; Max Rad Spec Prmt"},
  {id: "Oral &amp; Max Surg Spec Br Prmt", name: "Oral &amp; Max Surg Spec Br Prmt"},
  {id: "Oral &amp; Max Surgery Spec Prmt", name: "Oral &amp; Max Surgery Spec Prmt"},
  {id: "Oral Pathology Spec Br Permit", name: "Oral Pathology Spec Br Permit"},
  {id: "Oral Pathology Specialty Permit", name: "Oral Pathology Specialty Permit"},
  {id: "Orthodontics Spec Br Permit", name: "Orthodontics Spec Br Permit"},
  {id: "Orthodontics Specialty Permit", name: "Orthodontics Specialty Permit"},
  {id: "Orthotist", name: "Orthotist"},
  {id: "Orthotist Assistant", name: "Orthotist Assistant"},
  {id: "Out of State Pharmacist Immunization", name: "Out of State Pharmacist Immunization"},
  {id: "PCS Branch Permit", name: "PCS Branch Permit"},
  {id: "PCS Permit", name: "PCS Permit"},
  {id: "Pediatric-Pedodontic Spec Br Prmt", name: "Pediatric-Pedodontic Spec Br Prmt"},
  {id: "Pediatric-Pedodontic Spec Prmt", name: "Pediatric-Pedodontic Spec Prmt"},
  {id: "Pedorthist", name: "Pedorthist"},
  {id: "Perfusionist", name: "Perfusionist"},
  {id: "Periodontics Spec Br Permit", name: "Periodontics Spec Br Permit"},
  {id: "Periodontics Specialty Permit", name: "Periodontics Specialty Permit"},
  {id: "Pharmacist", name: "Pharmacist"},
  {id: "Pharmacist Graduate License", name: "Pharmacist Graduate License"},
  {id: "Pharmacist Intern", name: "Pharmacist Intern"},
  {id: "Pharmacy Intern Immunization Approval", name: "Pharmacy Intern Immunization Approval"},
  {id: "Pharmacy Tech Immunization Approval", name: "Pharmacy Tech Immunization Approval"},
  {id: "Pharmacy Technician", name: "Pharmacy Technician"},
  {id: "Physical Therapist", name: "Physical Therapist"},
  {id: "Physical Therapist Asst", name: "Physical Therapist Asst"},
  {id: "Physician Assistant", name: "Physician Assistant"},
  {id: "Physician Assistant Graduate License", name: "Physician Assistant Graduate License"},
  {id: "Planner-In-Training", name: "Planner-In-Training"},
  {id: "Plumber Candidate", name: "Plumber Candidate"},
  {id: "Plumber CE Sponsor", name: "Plumber CE Sponsor"},
  {id: "Podiatrist", name: "Podiatrist"},
  {id: "Practicing Psychologist", name: "Practicing Psychologist"},
  {id: "Preceptor Certificate", name: "Preceptor Certificate"},
  {id: "Prof. Engineer &amp; Land Surveyor", name: "Prof. Engineer &amp; Land Surveyor"},
  {id: "Professional Art Therapist", name: "Professional Art Therapist"},
  {id: "Professional Art Therapist", name: "Professional Art Therapist"},
  {id: "Professional Counselor", name: "Professional Counselor"},
  {id: "Professional Engineer", name: "Professional Engineer"},
  {id: "Professional Land Surveyor", name: "Professional Land Surveyor"},
  {id: "Professional Music Therapist", name: "Professional Music Therapist"},
  {id: "Professional Planner", name: "Professional Planner"},
  {id: "Prosthetist", name: "Prosthetist"},
  {id: "Prosthetist &amp; Orthotist", name: "Prosthetist &amp; Orthotist"},
  {id: "Prosthetist &amp; Orthotist Asst", name: "Prosthetist &amp; Orthotist Asst"},
  {id: "Prosthetist Assistant", name: "Prosthetist Assistant"},
  {id: "Prosthodontics Spec Br Permit", name: "Prosthodontics Spec Br Permit"},
  {id: "Prosthodontics Specialty Prmt", name: "Prosthodontics Specialty Prmt"},
  {id: "Public Accountant", name: "Public Accountant"},
  {id: "Public Health Spec Br Permit", name: "Public Health Spec Br Permit"},
  {id: "Public Health Specialty Permit", name: "Public Health Specialty Permit"},
  {id: "Public School Accountant", name: "Public School Accountant"},
  {id: "Qualified Background Check", name: "Qualified Background Check"},
  {id: "Recreational Therapist", name: "Recreational Therapist"},
  {id: "Reg Municipal Accountant", name: "Reg Municipal Accountant"},
  {id: "Reg Municipal Accountant (Only)", name: "Reg Municipal Accountant (Only)"},
  {id: "Reg. Prof. Nurse - Single State", name: "Reg. Prof. Nurse - Single State"},
  {id: "Reg.Prof. Nurse - Multi State", name: "Reg.Prof. Nurse - Multi State"},
  {id: "Registered  Advisor", name: "Registered  Advisor"},
  {id: "Registered Architect", name: "Registered Architect"},
  {id: "Registered Dental Assistant", name: "Registered Dental Assistant"},
  {id: "Rehabilitation Counselor", name: "Rehabilitation Counselor"},
  {id: "Researcher", name: "Researcher"},
  {id: "Resident Permit", name: "Resident Permit"},
  {id: "Respiratory Care Graduate License", name: "Respiratory Care Graduate License"},
  {id: "Respiratory Care Practitioner", name: "Respiratory Care Practitioner"},
  {id: "Respiratory Care Temporary", name: "Respiratory Care Temporary"},
  {id: "RPN Graduate License", name: "RPN Graduate License"},
  {id: "Salesperson", name: "Salesperson"},
  {id: "Salesperson- Branch License", name: "Salesperson- Branch License"},
  {id: "Skin Care Specialist", name: "Skin Care Specialist"},
  {id: "SLP Clin Internship Temp License", name: "SLP Clin Internship Temp License"},
  {id: "Specialty Permits", name: "Specialty Permits"},
  {id: "Specialty Permits Branch", name: "Specialty Permits Branch"},
  {id: "Speech Lang Pathologist-1YR TEMP", name: "Speech Lang Pathologist-1YR TEMP"},
  {id: "Speech Language Pathologist", name: "Speech Language Pathologist"},
  {id: "Student Permit", name: "Student Permit"},
  {id: "Student Registration", name: "Student Registration"},
  {id: "Teacher", name: "Teacher"},
  {id: "Technician", name: "Technician"},
  {id: "Technologist", name: "Technologist"},
  {id: "Telecom Wiring Exempt-Person", name: "Telecom Wiring Exempt-Person"},
  {id: "Temp Ophthalmic Disp Branch", name: "Temp Ophthalmic Disp Branch"},
  {id: "Temp Ophthalmic Tech Branch", name: "Temp Ophthalmic Tech Branch"},
  {id: "TEMP Visiting Physical Therapist", name: "TEMP Visiting Physical Therapist"},
  {id: "Temporary Dispenser", name: "Temporary Dispenser"},
  {id: "Temporary Ophthalmic Dispenser", name: "Temporary Ophthalmic Dispenser"},
  {id: "Temporary Ophthalmic Tech", name: "Temporary Ophthalmic Tech"},
  {id: "Temporary Perfusionist License", name: "Temporary Perfusionist License"},
  {id: "Temporary Permit", name: "Temporary Permit"},
  {id: "Temporary Permit", name: "Temporary Permit"},
  {id: "Temporary Physician Assistant", name: "Temporary Physician Assistant"},
  {id: "Temporary Practice Permit", name: "Temporary Practice Permit"},
  {id: "Temporary Privileges", name: "Temporary Privileges"},
  {id: "Temporary Registered Reporter", name: "Temporary Registered Reporter"},
  {id: "Temporary Salesperson", name: "Temporary Salesperson"},
  {id: "Temporary Salesperson Branch", name: "Temporary Salesperson Branch"},
  {id: "Three-Year Permit", name: "Three-Year Permit"},
  {id: "TPA Certification", name: "TPA Certification"},
  {id: "Trainee", name: "Trainee"},
  {id: "Trainee Permit", name: "Trainee Permit"},
  {id: "Veterinarian", name: "Veterinarian"},
  {id: "Veterinarian Branch", name: "Veterinarian Branch"},
  {id: "Veterinarian Non-Active", name: "Veterinarian Non-Active"},
  {id: "Veterinarian Temporary Permit", name: "Veterinarian Temporary Permit"}
];
