import {Admin, Resource} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import {UsersList, UserCreate, UserEdit} from "./Users";
import authProvider from './authProvider';
import {DebarmentList} from "./Debarments";
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
const dataProvider = jsonServerProvider("");

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource name="users" options={{label: 'Personnel'}} icon={MonitorHeartOutlinedIcon} list={UsersList} create={UserCreate} edit={UserEdit}/>
    <Resource name="debarments" icon={DangerousOutlinedIcon} list={DebarmentList}/>
  </Admin>
);

export default App;
