const authProvider = {
  login: async ({username, password}) => {
    const response = await fetch('/auth/login', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        username,
        password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      let data = await response.json();
      localStorage.setItem('username', data.name);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },

  logout: () => {
    localStorage.removeItem('username');
    fetch('/auth/logout')
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  getIdentity: () =>
    Promise.resolve({fullName: localStorage.getItem('username')}),

  getPermissions: () => Promise.resolve(''),
};

export default authProvider;
