import {Datagrid, Filter, List, Pagination, TextInput, DateField, TextField, BooleanInput} from "react-admin";

const Filters = props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
    <TextInput source="title" alwaysOn/>
    <BooleanInput source="isMedicaid" alwaysOn options={{}}/>
    <BooleanInput source="isTreasury" alwaysOn options={{}}/>
  </Filter>
);

const PaginationInstance = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />
);

export const DebarmentList = (props) => (
  <List
    {...props}
    pagination={<PaginationInstance/>}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField source="name"/>
      <TextField source="title"/>
      <TextField source="source"/>
      <DateField source="birthDate"/>
      <DateField source="start"/>
      <DateField source="end"/>
    </Datagrid>
  </List>
);
