import * as React from 'react';
import {Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {stringify} from 'query-string';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import {useRecordContext} from 'react-admin';


const LinkToDebarments = (props) => {
  const data = useRecordContext(props);
  const names = (data.name || '').split(' ');
  const parsedName = names && names.length > 1 ? `${names[1]}, ${names[0]}` : '';
  if (data.isValidLicenseMedicaid && data.isValidLicenseTreasury) return;
  return (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: '/debarments',
        search: stringify({
          filter: JSON.stringify({name: parsedName, title: data.title}),
        }),
      }}
      state={{_scrollToTop: true}}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <DangerousOutlinedIcon/>
      DEBARMENTS
    </Button>
  );
};

export default LinkToDebarments;
