import React, {Component} from 'react';
import {Button} from 'react-admin';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

class CustomExport extends Component {
  handleClick = async () => {
    const response = await fetch('/usersCSV', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(this.props.filterValues),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'users.csv';
    link.click();
    setTimeout(() => URL.revokeObjectURL(link.href), 0);
  };

  render() {
    return (
      <Button label="Export" onClick={this.handleClick}><FileDownloadOutlinedIcon/></Button>
    )
  }
}

export default CustomExport;
