import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  Pagination,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  UrlField,
  DateTimeInput,
  useListContext
} from "react-admin";
import {ImportButton} from "react-admin-import-csv";
import {CreateButton} from "ra-ui-materialui";
import {licenseTypes, professions} from "./constants";
import CustomExport from "./CustomExport";
import LinkToDebarments from "./LinkToRelatedDebarments";

const Actions = (props) => {
  const {className, basePath} = props;
  const { filterValues } = useListContext();
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath}/>
      <CustomExport filterValues={filterValues}/>
      <ImportButton {...props} />
    </TopToolbar>
  );
};

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" validate={[required()]}/>
      <TextInput source="lastName" validate={[required()]}/>
      <TextInput source="licenseNumber" validate={[required()]}/>
      <TextInput source="title"/>
      <TextInput source="email"/>
      <DateInput source="birthDate"/>
      <TextInput source="phoneNumber"/>
      <SelectInput source="profession" optionText="name" choices={professions}/>
      <SelectInput source="licenseType" optionText="name" choices={licenseTypes}/>
      <TextInput source="city"/>
    </SimpleForm>
  </Create>
);

export const UserEdit = (props) => (
  <Edit {...props} title="">
    <SimpleForm>
      <TextField label="Id" source="id" InputProps={{
        readOnly: true,
      }}/>
      <TextInput source="firstName" validate={[required()]}/>
      <TextInput source="lastName" validate={[required()]}/>
      <TextInput source="licenseNumber" validate={[required()]}/>
      <TextInput source="title"/>
      <TextInput source="email"/>
      <DateInput source="birthDate"/>
      <TextInput source="phoneNumber"/>
      <SelectInput source="profession" optionText="name" choices={professions}/>
      <SelectInput source="licenseType" optionText="name" choices={licenseTypes}/>
      <TextInput source="city"/>
    </SimpleForm>
  </Edit>
);

const Filters = props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
    <TextInput source="licenseNumber" alwaysOn/>
    <TextInput source="email" alwaysOn/>
    <DateTimeInput source="addedDate" alwaysOn/>
    <BooleanInput source="isValidLicense" alwaysOn options={{}}/>
    <BooleanInput source="isInvalidLicense" alwaysOn options={{}}/>
  </Filter>
);

const PaginationInstance = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />
);

export const UsersList = (props) => (
  <List
    {...props}
    pagination={<PaginationInstance/>}
    actions={<Actions/>}
    filters={<Filters/>}
  >
    <Datagrid>
      <TextField source="name"/>
      <TextField source="licenseNumber"/>
      <TextField source="title"/>
      <DateField source="addedDate" showTime/>
      <DateField source="lastRefresh" showTime/>
      <BooleanField source="isValidLicenseConsumerAffairs"/>
      <BooleanField source="isValidLicenseTreasury"/>
      <BooleanField source="isValidLicenseMedicaid"/>
      <LinkToDebarments source="debarments"/>
      <TextField source="email"/>
      <DateField source="birthDate"/>
      <TextField source="phoneNumber"/>
      <TextField source="profession"/>
      <TextField source="licenseType"/>
      <TextField source="city"/>
      <UrlField target='_blank' source="consumerAffairs"/>
      <UrlField target='_blank' source="treasury"/>
      <UrlField target='_blank' source="medicaid"/>
      <EditButton/>
    </Datagrid>
  </List>
);
